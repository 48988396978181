/* eslint-disable max-lines-per-function */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  Typography,
} from "@mui/material";
import { useAtom } from "jotai";

import { supportModalVisibilityAtom } from "../../state/supportModalState";

export default function SupportModal() {
  const [isOpen, setIsOpen] = useAtom(supportModalVisibilityAtom);

  const handleClose = () => setIsOpen(false);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="support-dialog-title"
    >
      <DialogTitle id="support-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">Need Help?</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: "grey.500" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid2 container spacing={3}>
          <Grid2 size={6}>
            <Typography variant="h6" gutterBottom>
              Contact Support
            </Typography>
            <Typography>
              Reach out to our support team for personalized assistance.
              <a href="mailto:truetracts.support@truefootage.tech">
                Truetracts.Support@truefootage.tech
              </a>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href="mailto:truetracts.support@truefootage.tech"
              sx={{ mt: 2 }}
            >
              Contact Support
            </Button>
          </Grid2>
          <Grid2 size={6}>
            <Typography variant="h6" gutterBottom>
              FAQs
            </Typography>
            <Typography>
              Find answers to commonly asked questions about our platform.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href="https://truefootage.tech/tools/truetracts/faq"
              target="_blank"
              sx={{ mt: 2 }}
            >
              View FAQs
            </Button>
          </Grid2>
          <Grid2 size={6}>
            <Typography variant="h6" gutterBottom>
              Join Daily Office Hours
            </Typography>
            <Typography>
              Office Hours are M-F from 12 pm - 1pm CT beginning January 17!
              <br />
              <br />
              Meeting Link:{" "}
              <a href="https://meet.google.com/skf-jprv-dbr">
                meet.google.com/skf-jprv-dbr
              </a>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href="https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MnVyZHAwdjF0OTM3ajF2ZDFucGQycHBxNmRfMjAyNTAxMTdUMTgwMDAwWiBwYXVsLnJvd2VAdHJ1ZWZvb3RhZ2UudGVjaA&tmsrc=paul.rowe%40truefootage.tech&scp=ALL"
              target="_blank"
              sx={{ mt: 2 }}
            >
              Add To Calendar
            </Button>
          </Grid2>
          <Grid2 size={6}>
            <Typography variant="h6" gutterBottom>
              Coverage
            </Typography>
            <Typography>
              See if your county is covered by TrueTracts.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href="https://truefootage.tech/tools/truetracts/mls-coverage"
              target="_blank"
              sx={{ mt: 2 }}
            >
              View Coverage
            </Button>
          </Grid2>
          <Grid2 size={12}>
            <iframe
              width="852"
              height="400"
              src="https://www.youtube.com/embed/2wI3l_Fsmb0?si=64O3jtjL2os6-Uea"
              title="TrueTracts Full Training Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Grid2>
        </Grid2>
      </DialogContent>
    </Dialog>
  );
}
