import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Paper,
  Typography,
} from "@mui/material";

import { FeatureFlagGroupDefinitions } from "~/common/state/featureFlags/featureFlagList";
import SettingsDialogFeatureFlag from "./SettingsDialogFeatureFlag";

interface SettingsDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function SettingsDialog({ open, onClose }: SettingsDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        {Object.entries(FeatureFlagGroupDefinitions).map(([groupId, group]) => (
          <Paper key={groupId} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="h6">{group.label}</Typography>
            <FormGroup>
              {group.featureFlagIds.map((featureFlagId) => (
                <SettingsDialogFeatureFlag
                  key={featureFlagId}
                  id={featureFlagId}
                />
              ))}
            </FormGroup>
          </Paper>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
