import { useLocation } from "@remix-run/react";
import { useAtomValue, useSetAtom } from "jotai";
import React, { lazy } from "react";

import {
  currentStepAtom,
  selectStepAtom,
  ValuationProcessStep,
} from "../state/valuationProcessState";

type ReactNode = React.ReactNode;
const empty = null;

const MarketAnalysisHeader = lazy(
  () => import("~/marketAnalysis/components/Header/MarketAnalysisHeader"),
);

const SessionsHeader = lazy(
  () => import("~/sessions/components/SessionsHeader"),
);

const BoundariesHeader = lazy(
  () => import("~/boundaries/components/Header/BoundariesHeader"),
);

const CompRecommendationHeader = lazy(
  () =>
    import("~/compRecommendation/components/Header/CompRecommendationHeader"),
);

const stepComponentMapping: Record<ValuationProcessStep, ReactNode> = {
  [ValuationProcessStep.Sessions]: <SessionsHeader />,
  [ValuationProcessStep.Boundaries]: <BoundariesHeader />,
  [ValuationProcessStep.MarketAnalysis]: <MarketAnalysisHeader />,
  [ValuationProcessStep.PreliminaryAdjustments]: empty,
  [ValuationProcessStep.CompRecommendation]: <CompRecommendationHeader />,
  [ValuationProcessStep.CompRec]: empty,
  [ValuationProcessStep.Adjustments]: empty,
  [ValuationProcessStep.Reconciliation]: empty,
  [ValuationProcessStep.Export]: empty,
};

export default function TrueTractsHeader() {
  const currentStep = useAtomValue(currentStepAtom);
  const setCurrentStep = useSetAtom(selectStepAtom);
  const currentPath = useLocation().pathname;
  if (currentPath === "/truetracts") {
    setCurrentStep(ValuationProcessStep.Sessions);
  }

  const Component = stepComponentMapping[currentStep];

  if (Component == null) {
    return null;
  }

  return Component;
}
