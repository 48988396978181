import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useSetAtom } from "jotai";

import {
  FeatureFlagDefinitions,
  FeatureFlagId,
} from "~/common/state/featureFlags/featureFlagList";
import {
  setFeatureFlagStateAtom,
  useFeatureFlagAtomValue,
} from "~/common/state/featureFlagsState";

interface SettingsDialogFeatureFlagProps {
  id: FeatureFlagId;
}

export default function SettingsDialogFeatureFlag({
  id,
}: SettingsDialogFeatureFlagProps) {
  const featureFlag = useFeatureFlagAtomValue(id);
  const setFeatureFlagState = useSetAtom(setFeatureFlagStateAtom);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={featureFlag}
          onChange={() => setFeatureFlagState(id, !featureFlag)}
        />
      }
      label={
        <Typography variant="body1">
          {FeatureFlagDefinitions[id].label}
        </Typography>
      }
    />
  );
}
